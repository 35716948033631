<template>
  <main>
    <ul class="h-[95vh] space-y-5 mt-3 p-3 text-sm text-nowrap">
      <li v-for="(route, index) in MWRoutes" :key="index">
        <div
          v-if="route.name === 'Бүртгэл /хуучин бааз/' && !hiddenNavbar"
          class="relative text-center my-6"
        >
          <hr class="border-t border-gray-300" />
          <p
            class="absolute top-[-8px] left-4 bg-white text-xs text-gray-500 px-1"
          >
            Хэрэглэгчид
          </p>
        </div>

        <div
          v-if="route.name === 'Хөтөлбөрийн огноо' && !hiddenNavbar"
          class="relative text-center my-6"
        >
          <hr class="border-t border-gray-300" />
          <p
            class="absolute top-[-8px] left-4 bg-white text-xs text-gray-500 px-1"
          >
            Арга хэмжээ
          </p>
        </div>

        <div
          v-if="route.name === 'Илтгэгчид' && !hiddenNavbar"
          class="relative text-center my-6"
        >
          <hr class="border-t border-gray-300" />
          <p
            class="absolute top-[-8px] left-4 bg-white text-xs text-gray-500 px-1"
          >
            Илтгэл
          </p>
        </div>

        <div
          @click="route.children ? toggleMWMenu(index) : navigateToMW(route.to)"
          class="flex items-center justify-between rounded-md cursor-pointer"
          :class="{
            'justify-center': hiddenNavbar,
            'text-[#00a375]': isRoute === `/admin/week${route.to}`,
            'hover:text-[#00a375]':
              isRoute !== `/admin/week${route.to}` && route.to !== null,
          }"
        >
          <div
            class="flex items-center"
            :class="hiddenNavbar ? 'justify-center' : 'gap-2'"
          >
            <font-awesome-icon
              :icon="`fa-solid fa-${route.icon}`"
              class="w-5"
              :class="hiddenNavbar ? 'text-lg' : 'text-sm'"
            />
            <h6 :class="hiddenNavbar ? 'hidden' : 'block'">
              {{ route.name }}
            </h6>
          </div>
          <font-awesome-icon
            v-if="route.children && !hiddenNavbar"
            :icon="
              menuMWIndices.includes(index)
                ? 'fa-solid fa-chevron-down'
                : 'fa-solid fa-chevron-left'
            "
          />
        </div>

        <ul
          v-if="
            route.children &&
            route.children.length &&
            menuMWIndices.includes(index)
          "
          class="pl-7 mt-3 space-y-3 overflow-hidden"
        >
          <li v-for="(child, childIndex) in route.children" :key="childIndex">
            <span v-if="child.grandchildren && child.grandchildren.length"
              ><font-awesome-icon icon="fa-regular fa-circle" class="mr-1" />{{
                child.name
              }}</span
            >
            <router-link
              v-else
              :to="`/admin/week${child.to}`"
              :class="
                isRoute === `/admin/week${child.to}`
                  ? 'text-[#00a375]'
                  : 'hover:text-[#00a375]'
              "
            >
              <font-awesome-icon
                v-if="isRoute === `/admin/week${child.to}`"
                icon="fa-regular fa-circle-dot"
                class="mr-1"
              />
              <font-awesome-icon
                v-else
                icon="fa-regular fa-circle"
                class="mr-1"
              />
              {{ child.name }}
            </router-link>

            <ul
              v-if="child.grandchildren && child.grandchildren.length"
              class="pl-5 mt-3 space-y-3 overflow-hidden"
            >
              <li
                v-for="(child, childIndex) in child.grandchildren"
                :key="childIndex"
              >
                <router-link
                  :to="`/admin/week${child.to}`"
                  :class="
                    isRoute === `/admin/week${child.to}`
                      ? 'text-[#00a375]'
                      : 'hover:text-[#00a375]'
                  "
                >
                  {{ child.name }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </main>
</template>

<script>
import { onMounted, ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "MWAdminNavbar",
  props: {
    hiddenNavbar: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const account = computed(() => store.getters["admin/getAdminInformation"]);

    const router = useRouter();
    const route = useRoute();
    const isRoute = ref(route.path);

    watch(
      () => route.path,
      (newRoute) => {
        isRoute.value = newRoute;
      }
    );

    const MWRoutes = [
      {
        name: "Вэбсайтын тохиргоо",
        to: null,
        icon: "sliders",
        children: [
          {
            name: "Сошиал холбоос",
            to: "/social-link",
          },
          {
            name: "Холбогдох мэдээлэл",
            to: "/footer",
          },
        ],
      },
      {
        name: "Бүртгэл /хуучин бааз/",
        to: "/register",
        icon: "users",
      },
      {
        name: "Бүртгэл /шинэ бааз/",
        to: "/new-register",
        icon: "users",
      },
      {
        name: "Хөтөлбөрийн огноо",
        to: "/event-date",
        icon: "calendar-days",
      },
      {
        name: "Хөтөлбөр",
        to: "/agenda",
        icon: "list",
      },
      {
        name: "Илтгэгчид",
        to: "/presenters",
        icon: "play",
      },
      {
        name: "Илтгэлүүд",
        to: "/presentation",
        icon: "list",
      },
      // {
      //   name: "Нүүр хуудас",
      //   to: "/home",
      //   icon: "home",
      // },
      // {
      //   name: "Мэдээ мэдээлэл",
      //   to: null,
      //   icon: "newspaper",
      //   children: [
      //     {
      //       name: "Мэдээ нэмэх",
      //       to: "/add-news",
      //     },
      //     {
      //       name: "Мэдээ засварлах",
      //       to: "/update-news",
      //     },
      //   ],
      // },
    ];

    onMounted(async () => {
      MWRoutes.forEach((route, index) => {
        if (route.children) {
          route.children.forEach((child) => {
            if (`/admin/week${child.to}` === isRoute.value) {
              menuMWIndices.value.push(index);
            }
          });
        }
      });
    });

    const navigateToMW = (to) => {
      router.push(`/admin/week${to}`);
    };

    const menuMWIndices = ref([]);
    const toggleMWMenu = (index) => {
      const idx = menuMWIndices.value.indexOf(index);
      if (idx > -1) {
        menuMWIndices.value.splice(idx, 1);
      } else {
        menuMWIndices.value.push(index);
      }
    };

    return {
      isRoute,
      MWRoutes,
      account,
      navigateToMW,
      menuMWIndices,
      toggleMWMenu,
    };
  },
};
</script>
