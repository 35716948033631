<template>
  <MWBackground>
    <div class="flex justify-center w-full py-10 z-10">
      <main
        class="min-h-screen w-full min-[1200px]:w-4/5 max-[1199px]:px-5 text-white"
      >
        <MWOrganiserLogos class="flex justify-start items-center w-full" />

        <section>
          <div
            class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-between items-center gap-3 lg:gap-10 mt-10"
          >
            <button
              v-for="item in agenda"
              :key="item.event_date_id"
              @click="selectDay(item.event_date_id)"
              class="block w-full p-2 text-center"
              :class="
                selectedDay === item.event_date_id
                  ? 'bg-[#085A43]'
                  : 'bg-[#009269] hover:bg-[#085A43]'
              "
            >
              <p class="uppercase font-bold">
                {{ item.day }}
              </p>
              <p class="text-xs">
                {{ item.event_date }}
              </p>
            </button>
          </div>

          <div
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 justify-between items-center gap-3 lg:gap-10 mt-5"
          >
            <button
              v-for="item in halls"
              :key="item.id"
              @click="selectHall(item.id)"
              class="block w-full p-2 text-center max-lg:text-xs"
              :class="
                selectedHall === item.id
                  ? 'bg-[#085A43]'
                  : 'bg-[#009269] hover:bg-[#085A43]'
              "
            >
              <p class="uppercase lg:font-bold">
                {{ lang === "mn" ? item.hall_name : item.hall_name_en }}
              </p>
            </button>
          </div>

          <div
            v-if="!isPdfLoading && presentations.length > 0"
            class="space-y-10 mt-10"
          >
            <div v-for="pdf in presentations" :key="pdf.id" class="mb-6">
              <h3 class="text-end text-white italic text-sm mb-2">
                {{ pdf.present_name }}
              </h3>
              <iframe
                :src="`${pdf.present_url}`"
                frameborder="0"
                class="w-full h-[40rem]"
              ></iframe>
            </div>
          </div>

          <div
            v-else-if="!isPdfLoading && presentations.length === 0"
            class="flex justify-center items-center h-[50vh]"
          >
            {{
              lang === "mn"
                ? "Илтгэл байхгүй байна"
                : "No presentation is available"
            }}
          </div>

          <div v-else class="flex justify-center items-center h-[50vh]">
            <BarLoading />
          </div>
        </section>

        <h1 class="font-bold text-xl text-center sm:text-2xl uppercase mt-20">
          {{ lang === "mn" ? "Тэргүүлэх илтгэгчид" : "Key speakers" }}
        </h1>

        <div
          v-if="presenters.length > 0 && !isLoading"
          class="grid grid-cols-2 min-[400px]:grid-cols-3 min-[650px]:grid-cols-4 md:grid-cols-5 min-[900px]:grid-cols-6 min-[1200px]:grid-cols-8 gap-3 md:gap-5 mt-5 mb-20"
        >
          <div v-for="speaker in presenters" :key="speaker.id">
            <img
              v-if="speaker.profile_img"
              :src="speaker.profile_img"
              alt="profile"
              class="h-36 w-full object-cover object-top"
            />
            <div v-else class="bg-white h-36 w-full"></div>
            <h6
              class="uppercase text-xs font-bold mt-1 max-[1200px]:text-center"
            >
              {{ lang === "mn" ? speaker.name_mn : speaker.name_en }}
            </h6>
            <h6 class="text-[0.65rem] font-thin mt-1">
              {{ lang === "mn" ? speaker.position_mn : speaker.position_en }}
            </h6>
          </div>
        </div>

        <div
          v-else-if="presenters.length === 0 && !isLoading"
          class="flex justify-center items-center mt-20 lg:mt-40"
        >
          {{
            lang === "mn"
              ? "Уучлаарай, илтгэгчидийн мэдээлэл хараахан гараагүй байна."
              : "Sorry, speaker information is not available yet."
          }}
        </div>

        <div v-else class="flex justify-center items-center mt-20 lg:mt-40">
          <BarLoading />
        </div>
      </main>
    </div>
  </MWBackground>
</template>

<script>
import MWBackground from "@/components/MiningWeek/MW2024/MWBackground.vue";
import MWOrganiserLogos from "@/components/MiningWeek/MW2024/MWOrganiserLogos";
import BarLoading from "@/components/ui/BarLoading.vue";
import clientMWInstance from "@/lib/clientMWInstance";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "MWPresentation",
  components: {
    MWBackground,
    MWOrganiserLogos,
    BarLoading,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const isLoading = ref(false);
    const isPdfLoading = ref(false);
    const presenters = ref([]);
    const agenda = ref([]);
    const halls = ref([]);
    const presentations = ref([]);

    const selectedDay = ref(null);
    const selectDay = (event_date_id) => {
      selectedDay.value = event_date_id;
    };

    const selectedHall = ref(1);
    const selectHall = (id) => {
      selectedHall.value = id;
    };

    const fetchData = async () => {
      try {
        isLoading.value = true;
        const res = await clientMWInstance.get(`/get/presenter?id=${3}`);
        if (res.status === 200) {
          presenters.value = res.data.presenter;
        }

        const hallRes = await clientMWInstance.get(`/get/hall?event=${3}`);
        if (hallRes.status === 200) {
          halls.value = hallRes.data.hall;
          if (halls.value.length > 0) {
            selectedHall.value = halls.value[0].id;
            console.log(selectedHall.value);
          }
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    const fetchAgenda = async () => {
      const language = lang.value === "mn" ? 1 : 2;
      const agendaRes = await clientMWInstance.get(
        `/get/agenda?event=${3}&language=${language}`
      );
      if (agendaRes.status === 200) {
        agenda.value = agendaRes.data.agenda;
        if (agenda.value.length > 0) {
          selectedDay.value = agenda.value[0].event_date_id;
        }
      }
    };

    const fetchPresentations = async () => {
      try {
        console.log(selectedHall.value);
        isPdfLoading.value = true;
        const res = await clientMWInstance.get(
          `/get/presentation?hall=${selectedHall.value}&agenda=${selectedDay.value}`
        );
        if (res.status === 200) {
          presentations.value = res.data.presentations;
        }
      } catch (err) {
        return err;
      } finally {
        isPdfLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchAgenda();
      await fetchData();
      await fetchPresentations();
    });

    watch([selectedDay, selectedHall], () => {
      fetchPresentations();
    });

    watch([lang], () => {
      fetchAgenda();
    });

    return {
      lang,
      isLoading,
      presenters,
      agenda,
      selectDay,
      selectedDay,
      halls,
      selectedHall,
      selectHall,
      presentations,
      isPdfLoading,
    };
  },
};
</script>
